import React, { useState } from "react";
import { PricingTabs } from "../Calculator/PricingTabs";
import { SummarySection } from "../Calculator/SummarySection";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Business } from "../Calculator/TabsContent/Business";
import { Market } from "../Calculator/TabsContent/Market";
import { Marketing } from "../Calculator/TabsContent/Marketing";
import { ThankyouSection } from "../Calculator/TabsContent/ThankyouSection";
import { useTranslation } from "react-i18next";
import { useTabContext } from "../../context/TabContext";


const reCAPTCHASiteKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY;

export const PricingSection = () => {

    const { t } = useTranslation();


    const vouchersList = [
        {
            id: 1,
            label: 1,
            value: 0.4
        },
        {
            id: 2,
            label: 2,
            value: 0.6,
        },
        {
            id: 3,
            label: 3,
            value: 1,
        },

    ];
    const numberOfMerchantsList = [
        {
            id: 1,
            label: 50,
            value: 50
        },
        {
            id: 2,
            label: 100,
            value: 100
        },
        {
            id: 3,
            label: 150,
            value: 150
        },
        {
            id: 4,
            label: 200,
            value: 200
        },
        {
            id: 5,
            label: 250,
            value: 250
        },
        {
            id: 6,
            label: 300,
            value: 300
        },
        {
            id: 7,
            label: 350,
            value: 350
        },
        {
            id: 8,
            label: 400,
            value: 400
        },
        {
            id: 9,
            label: 450,
            value: 450
        },
        {
            id: 10,
            label: 500,
            value: 500
        },
    ];
    const marketingCampaignList = [
        {
            id: 1,
            label: t("calculator.marketing_fields.Campaigns_dropdown.standard"),
            value: "standard",
        },
        {
            id: 2,
            label: t("calculator.marketing_fields.Campaigns_dropdown.quarterly"),
            value: "quarterly"
        },
        {
            id: 3,
            label: t("calculator.marketing_fields.Campaigns_dropdown.annual"),
            value: "annual"
        },
    ];
    const advanceReportingOptions = [
        {
            id: 1,
            label: t("calculator.marketing_fields.reporting_dropdown.yes"),
            value: "yes"
        },
        {
            id: 2,
            label: t("calculator.marketing_fields.reporting_dropdown.no"),
            value: "no"
        }
    ];
    //const defaultDateToday = new Date();
    //const defaultDateNextYear = new Date(defaultDateToday.getFullYear() + 1, defaultDateToday.getMonth(), defaultDateToday.getDate());
    const pricingTabList = [
        {
            id: 1,
            label: t("calculator.tabs.business"),
            value: "Business",
            class: "nav-link",
        },
        {
            id: 2,
            label: t("calculator.tabs.market"),
            value: "Market",
            class: "nav-link"
        },
        {
            id: 3,
            label: t("calculator.tabs.marketing"),
            value: "Marketing",
            class: "nav-link"
        },
    ];
    const monthWithPercentage = [
        {
            month: 12,
            percentage: "100%",
            value: 1
        },
        {
            month: 11,
            percentage: "94%",
            value: 0.94
        },
        {
            month: 10,
            percentage: "88%",
            value: 0.88
        },
        {
            month: 9,
            percentage: "82%",
            value: 0.82
        },
        {
            month: 8,
            percentage: "75%",
            value: 0.75
        },
        {
            month: 7,
            percentage: "67%",
            value: 0.67
        },
        {
            month: 6,
            percentage: "59%",
            value: 0.59
        },
        {
            month: 5,
            percentage: "50%",
            value: 0.50
        },
        {
            month: 4,
            percentage: "41%",
            value: 0.41
        },
        {
            month: 3,
            percentage: "32%",
            value: 0.32
        },
        {
            month: 2,
            percentage: "22%",
            value: 0.22
        },
        {
            month: 1,
            percentage: "11%",
            value: 0.11
        },


    ];



    const [businessBackground, setBusinessBackground] = useState("");
    const [rewardsUnit, setRewardsUnit] = useState("");
    const [vouchers, setVouchers] = useState(vouchersList[2].value);
    const [country, setCountry] = useState("KSA");
    const [setupFee, setSetupFee] = useState(0);
    const [numberOfMerchants, setNumberOfMerchants] = useState(numberOfMerchantsList[0].value);
    const [marketingCampaign, setMarketingCampaign] = useState(marketingCampaignList[0].value);
    const [advanceReporting, setAdvanceReporting] = useState("no");
    const [selectedCountries, setSelectedCountries] = useState(["KSA"]);
    const [selectedLocations, setSelectedLocations] = useState([{
        id: 1,
        label: t("calculator.market_fields.region_list.ksa"),
        value: "KSA",
    }]);
    const [thankyouState, setThankyouState] = useState(false);
    const [businessFinishData, setBusinessFinishData] = useState("");
    const [marketFinishData, setMarketFinishData] = useState("");
    const [isLoadingSetupFee, setIsLoadingSetupFee] = useState(false);
    const [isLoadingContentLicenseFee, setIsLoadingContentLicenseFee] = useState(false);
    const [summaryData, setSummaryData] = useState({});
    const [initialSetupFee, setInitialSetupFee] = useState(0);
    const [contentLicenseFee, setContentLicenseFee] = useState(0);
    const [selectedContentLicenseFee, setSelectedContentLicenseFee] = useState(0);
    const [marketingFee, setMarketingFee] = useState(0);
    const [advanceReportingFee, setAdvanceReportingFee] = useState(0);
    const [totalFee, setTotalFee] = useState(0);
    const [contractPeriod, setContractPeriod] = useState("12");
    const [isMonthly, setIsMonthly] = useState(false);
    const [marketFee, setMarketFee] = useState(0);
    const tabContext = useTabContext();

    const getPackagesData = localStorage.getItem("packagesData");
    const onBusinessFinish = (businessData) => {
        setBusinessFinishData(businessData);
    }
    const onMarketFinish = (marketData) => {
        setMarketFinishData(marketData);
    }

    const onBusinessSelection = (businessOption) => {
        setBusinessBackground(businessOption);
    }

    const onRewardsUnitSelection = (rewardsUnit) => {
        setRewardsUnit(rewardsUnit);
    }
    
    const onCountrySelection = (country) => {
        setSelectedCountries(country);
    }

    // Function to calculate Market Fee
    const calculateMarketFee = () => {
        let marketFee = 0;
        let rewardsStandardAmount = 0;
        let calculatedRoundedValue = roundNumber(rewardsUnit);

        if (getPackagesData !== 'undefined') {
            const element = JSON.parse(getPackagesData).filter((item) =>
                item.no_of_keys === calculatedRoundedValue.toString()
                && item.merchant_location === country.toString()
                && item.no_of_merchants === numberOfMerchants.toString()
            );

            if (element.length > 0) {

                rewardsStandardAmount = parseFloat(element[0].price.replace(/,/g, ''));
                const rewardPerUnitPrice = rewardsStandardAmount/calculatedRoundedValue;
                const rewardtPriceForTotalUnits = rewardPerUnitPrice * rewardsUnit;
                
                //handling offers per merchant
                marketFee = rewardtPriceForTotalUnits * vouchers;
                //handling months percentage
                const monthPercentage = monthWithPercentage.filter((item) =>
                    item.month.toString() === contractPeriod.toString()
                );
                if (contractPeriod > 0 && monthPercentage.length > 0) {
                    marketFee = marketFee * monthPercentage[0].value;
                }

                //handling location percentage
                if (selectedLocations.length > 1) {
                    const locationPercentageData = localStorage.getItem("locationPercentage");
                    const element = JSON.parse(locationPercentageData).filter((item) =>
                        item.location_number === selectedLocations.length
                    );
                    marketFee = marketFee * element[0].percentage_value
                }
                setMarketFee(marketFee);
            } else {
                // Alert or handle no record found case
                console.log('No record found');
            }
        } else {
            // Alert or handle no record found case
            console.log('No PackagesData found');
        }
    };
    // Function to round the number based on the specified rules
    //If the user inputs a number between 200 and 1000, round to the nearest multiple of 50.
    //If the user inputs a number between 1000 and 500,000, round to the nearest multiple of 1000.
    const roundNumber = (number) => {
        if (number >= 200 && number <= 1000) {
            return Math.round(number / 50) * 50;
        } else if (number > 1000 && number <= 500000) {
            return Math.round(number / 1000) * 1000;
        }
        return number;
    };

    return (
        <section className="pricing-section" id="pricing_Section">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h2 className="second-heading">{t("calculator.heading")}</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-7 col-12">
                        {thankyouState ? (
                            <ThankyouSection />
                        ) : (
                            <>
                                <PricingTabs
                                    // activeTab={activeTab}
                                    // onChangeTab={onChangeTab}
                                    pricingTabList={pricingTabList}
                                />
                                <GoogleReCaptchaProvider reCaptchaKey={reCAPTCHASiteKey}>
                                    <div className="tab-content" id="pills-tabContent">
                                        {tabContext.activeTab === t("calculator.tabs.business") && (
                                            <Business
                                                onBusinessFinish={onBusinessFinish}
                                                onBusinessSelection={onBusinessSelection}
                                                setSetupFee={setSetupFee}
                                            />
                                        )}
                                        {tabContext.activeTab === t("calculator.tabs.market") && (
                                            <Market
                                                onMarketFinish={onMarketFinish}
                                                onRewardsUnitSelection={onRewardsUnitSelection}
                                                vouchers={vouchers}
                                                setVoucher={setVouchers}
                                                voucherList={vouchersList}
                                                merchants={numberOfMerchants}
                                                setMerchants={setNumberOfMerchants}
                                                merchantNumberList={numberOfMerchantsList}
                                                selectedCountries={selectedCountries}
                                                onCountrySelection={onCountrySelection}
                                                selectedLocations={selectedLocations}
                                                setSelectedLocations={setSelectedLocations}
                                                country={country}
                                                setContentLicenseFee={setContentLicenseFee}
                                                contentLicenseFee={contentLicenseFee}
                                                selectedContentLicenseFee={selectedContentLicenseFee}
                                                setSelectedContentLicenseFee={setSelectedContentLicenseFee}
                                                setIsLoadingContentLicenseFee={setIsLoadingContentLicenseFee}
                                                contractPeriod={contractPeriod}
                                                setContractPeriod={setContractPeriod}
                                                calculateMarketFee={calculateMarketFee}
                                            />
                                        )}
                                        {tabContext.activeTab === t("calculator.tabs.marketing") && (
                                            <Marketing
                                                businessFinishData={businessFinishData}
                                                marketFinishData={marketFinishData}
                                                marketingCampaign={marketingCampaign}
                                                setMarketingCampaign={setMarketingCampaign}
                                                marketingCampaignList={marketingCampaignList}
                                                advanceReporting={advanceReporting}
                                                setAdvanceReporting={setAdvanceReporting}
                                                advanceReportingOptions={advanceReportingOptions}
                                                setThankyouState={setThankyouState}
                                                initialSetupFee={initialSetupFee}
                                                contentLicenseFee={contentLicenseFee}
                                                marketingFee={marketingFee}
                                                setMarketingFee={setMarketingFee}
                                                setupFee={setupFee}
                                                advanceReportingFee={advanceReportingFee}
                                                setAdvanceReportingFee={setAdvanceReportingFee}
                                                selectedCountries={selectedCountries}
                                                isMonthly={isMonthly}
                                                summaryData={summaryData}
                                            />
                                        )}
                                    </div>
                                </GoogleReCaptchaProvider>
                            </>
                        )}
                    </div>
                    <SummarySection
                        contractPeriod={contractPeriod}
                        selectedCountries={selectedCountries}
                        isLoadingSetupFee={isLoadingSetupFee}
                        setSummaryData={setSummaryData}
                        initialSetupFee={initialSetupFee}
                        isLoadingContentLicenseFee={isLoadingContentLicenseFee}
                        contentLicenseFee={contentLicenseFee}
                        marketingFee={marketingFee}
                        advanceReportingFee={advanceReportingFee}
                        totalFee={totalFee}
                        setTotalFee={setTotalFee}
                        setupFee={setupFee}
                        setContentLicenseFee={setContentLicenseFee}
                        setAdvanceReportingFee={setAdvanceReportingFee}
                        setIsMonthly={setIsMonthly}
                        marketFee={marketFee}
                    />
                </div>
            </div>
        </section>
    );
}
