import React from "react";
import {useTranslation} from "react-i18next";

export const VisionContent = () => {
    const {t} = useTranslation();
    return (
        <section className="vision">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="text-holder text-center">
                            <h2 className="second-heading">{t("about_page.vision_heading")}</h2>
                            <p>{t("about_page.vision_content")}</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="img-holder">
                            <img src="/images/vision-img.png" alt="About Image" className="img-fluid w-100" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}