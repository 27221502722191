import React from "react";
import {useTranslation} from "react-i18next";

export const StorySection = () => {
    const {t} = useTranslation();
    return (
        <section className="story-section">
            <div className="container">
                <div className="row flex-md-row-reverse">
                    <div className="col-md-6 col-12">
                        <div className="img-holder">
                            <img src="/images/iphone-group.png" alt="success story" className="img-fluid img-en" />
                            <img src="/images/iphone-group-ar.png" alt="success story" className="img-fluid img-ar" />
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="text-holder">
                            <span className="d-block title">{t("success_story.heading")}</span>
                            <p>{t("success_story.text")}</p>
                            <button onClick={() =>  window.location.href='/contact'} className="btn btn-primary">{t("success_story.btn_text")}</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
