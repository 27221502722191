import React from "react";
import {useTranslation} from "react-i18next";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export const ExperienceSection = () => {
    const {t} = useTranslation();

    let settings = {
        items: 1,
        loop: true,
        center: true,
        autoplayHoverPause: true,
        autoplayTimeout: 2000,
        autoplay: true,
    }

    return (
        <section className="expSlides-section">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h2 className="second-heading">{t("customer_experience.heading")}</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="instructions-wrap">
                            <div className="instructions">
                                <OwlCarousel className='owl-theme' {...settings}>
                                    <div className='item'>
                                        <img src="/images/slide-1.png" alt="slide-1" className="img-fluid img-en"/>
                                        <img src="/images/ar-slide-1.png" alt="slide-1" className="img-fluid img-ar"/>
                                    </div>
                                    <div className='item'>
                                        <img src="/images/slide-2.png" alt="slide-2" className="img-fluid img-en"/>
                                        <img src="/images/ar-slide-2.png" alt="slide-2" className="img-fluid img-ar"/>
                                    </div>
                                    <div className='item'>
                                        <img src="/images/slide-3.png" alt="slide-3" className="img-fluid img-en"/>
                                        <img src="/images/ar-slide-3.png" alt="slide-3" className="img-fluid img-ar"/>
                                    </div>
                                    <div className='item'>
                                        <img src="/images/slide-4.png" alt="slide-4" className="img-fluid img-en"/>
                                        <img src="/images/ar-slide-4.png" alt="slide-4" className="img-fluid img-ar"/>
                                    </div>
                                    <div className='item'>
                                        <img src="/images/slide-5.png" alt="slide-5" className="img-fluid img-en"/>
                                        <img src="/images/ar-slide-5.png" alt="slide-5" className="img-fluid img-ar"/>
                                    </div>
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="row">
                    <div className="col-12">
                        <div className="instructions-wrap">
                            <div className="instructions owl-carousel owl-theme">
                                <div className="item">
                                    <img src="/images/slide-1.png" alt="slide-2" className="img-fluid"/>
                                </div>
                                <div className="item">
                                    <img src="/images/slide-2.png" alt="slide-2" className="img-fluid"/>
                                </div>
                                <div className="item">
                                    <img src="/images/slide-3.png" alt="slide-3" className="img-fluid"/>
                                </div>
                                <div className="item">
                                    <img src="/images/slide-4.png" alt="slide-4" className="img-fluid"/>
                                </div>
                                <div className="item">
                                    <img src="/images/slide-5.png" alt="slide-5" className="img-fluid"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </section>
    );
}
