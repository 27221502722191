import React from "react";
import {useTranslation} from "react-i18next";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const EmpowerTeam = () => {
    const {t} = useTranslation();

    let settings = {
        items: 1,
        loop: true,
        center: true,
        autoplayHoverPause: true,
        autoplayTimeout: 2000,
        autoplay: false,
    }
  return (
    <section className="expSlides-section viewDesktop">
            <div className="container">
                <hr />
                <div className="row">
                    <div className="col-12 text-center">
                        <h2 className="second-heading">{t("empower_team.heading")}</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="instructions-wrap">
                            <div className="instructions">
                                <OwlCarousel className='owl-theme' {...settings}>
                                    <div className='item'>
                                        <img src="/images/dashboard-slide-1.png" alt="slide-1" className="img-fluid img-en"/>
                                        <img src="/images/dashboard-slide-1.png" alt="slide-1" className="img-fluid img-ar"/>
                                    </div>
                                    <div className='item'>
                                        <img src="/images/dashboard-slide-2.png" alt="slide-2" className="img-fluid img-en"/>
                                        <img src="/images/dashboard-slide-2.png" alt="slide-2" className="img-fluid img-ar"/>
                                    </div>
                                    <div className='item'>
                                        <img src="/images/dashboard-slide-3.png" alt="slide-3" className="img-fluid img-en"/>
                                        <img src="/images/dashboard-slide-3.png" alt="slide-3" className="img-fluid img-ar"/>
                                    </div>
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  )
}

export default EmpowerTeam