import React, { useState, useEffect } from 'react';
import {Link} from "react-router-dom";
import i18n from "../i18n/i18n";
import {useTranslation} from "react-i18next";
import {useTabContext} from "../context/TabContext";

export const Header = ({pathname}) => {
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };
    const onRequestDemo = () => {
        window.location.href='/contact';
    }

    const tabContext = useTabContext();
    const hash = window.location.hash;

    const {t} = useTranslation();

    const changeLanguageHandlerAR = () => {
        localStorage.setItem('selectedLanguage', 'ar');
        i18n.changeLanguage(localStorage.getItem('selectedLanguage'));
        if(tabContext.activeTab === "Business") {
            tabContext.setActiveTab(t("calculator.tabs.business"));
        }
        if(tabContext.activeTab === "Market") {
            tabContext.setActiveTab(t("calculator.tabs.market"));
        }
        if(tabContext.activeTab === "Marketing") {
            tabContext.setActiveTab(t("calculator.tabs.marketing"));
        }
        document.documentElement.setAttribute('lang', localStorage.getItem('selectedLanguage'));
        window.location.href=pathname;
    }
    const changeLanguageHandlerEN = () => {
        localStorage.setItem('selectedLanguage', 'en');
        i18n.changeLanguage(localStorage.getItem('selectedLanguage'));
        if(tabContext.activeTab === "الاعمال") {
            tabContext.setActiveTab(t("calculator.tabs.business"));
        }
        if(tabContext.activeTab === "السوق") {
            tabContext.setActiveTab(t("calculator.tabs.market"));
        }
        if(tabContext.activeTab === "التسويق") {
            tabContext.setActiveTab(t("calculator.tabs.marketing"));
        }
        document.documentElement.setAttribute('lang', localStorage.getItem('selectedLanguage'));
        window.location.href=pathname;
    }
    if(!localStorage.getItem("selectedLanguage")) {
        if(i18n.language === "en-US") {
            localStorage.setItem('selectedLanguage', "en");
        }
    }
    document.documentElement.setAttribute('lang', localStorage.getItem('selectedLanguage'));

    const [scrollClass, setScrollClass] = useState('');

    useEffect(() => {
        const handleScroll = () => {
          if (window.scrollY > 50) {
            setScrollClass('fix-header');
          } else {
            setScrollClass('');
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);


    return <header id="header" className={`fixed-top ${scrollClass}`}>
        <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
                <Link className="navbar-brand" to="/" onClick={scrollToTop}>
                    <img src="/images/entertainer-logo.svg" alt="the ENTERTAINER" className="img-fluid" />
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
                        aria-label="Toggle navigation">
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <div className="right-col d-lg-flex ms-auto align-items-center">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link className={pathname === "/" ? "nav-link active" : "nav-link"} to="/"
                                      onClick={() => {
                                          window.location.href = "/";
                                      }}>
                                    <span
                                        data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
                                        aria-controls="navbarNavDropdown" aria-expanded="false"
                                        aria-label="Toggle navigation">{t("header.navbar.home")}
                                    </span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={pathname === "/about" ? "nav-link active" : "nav-link"} to="/about" onClick={scrollToTop}>
                                    <span
                                        data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
                                        aria-controls="navbarNavDropdown" aria-expanded="false"
                                        aria-label="Toggle navigation">{t("header.navbar.about_us")}
                                    </span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={hash === "#other_solutions" ? "nav-link active" : "nav-link"} to="/#other_solutions">
                                    <span
                                        data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
                                        aria-controls="navbarNavDropdown" aria-expanded="false"
                                        aria-label="Toggle navigation">{t("header.navbar.our_solutions")}
                                    </span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={pathname === "/contact" ? "nav-link active" : "nav-link"} to="/contact" onClick={scrollToTop}>
                                    <span
                                        data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
                                        aria-controls="navbarNavDropdown" aria-expanded="false"
                                        aria-label="Toggle navigation">{t("header.navbar.contact_us")}
                                    </span>
                                </Link>
                            </li>
                        </ul>
                        <div className="sec-right d-flex align-items-center">
							<span className="region d-flex align-items-center">
                                <img src="/images/ksa-flag.svg" alt="KSA" className="img-fluid" />{t("header.navbar.country")}
							</span>
                            <div className="language">
                                <span className="langAR" onClick={changeLanguageHandlerAR}
                                    data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
                                    aria-controls="navbarNavDropdown" aria-expanded="false"
                                    aria-label="Toggle navigation">العربيّة</span>
                                <span className="langEN" onClick={changeLanguageHandlerEN}
                                      data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
                                      aria-controls="navbarNavDropdown" aria-expanded="false"
                                      aria-label="Toggle navigation">English</span>
                            </div>
                            <span
                                data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
                                aria-controls="navbarNavDropdown" aria-expanded="false"
                                aria-label="Toggle navigation">
                                {/*<button onClick={() =>  window.location.href='/contact'} className="btn btn-primary">*/}
                                {/*    {t("header.navbar.request_demo")}*/}
                                {/*</button>*/}
                                <button onClick={onRequestDemo} className="btn btn-primary">
                                    {t("header.navbar.request_demo")}
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </header>;
}
