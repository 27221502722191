import React from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const OtherSolution = () => {
    const {t} = useTranslation();
    return (
        <section className="oth-solutions container" id="other_solutions">
            <div className="row">
                <div className="col-12">
                    <h2 className="second-heading text-center">{t("our_solutions.heading")}</h2>
                </div>
            </div>
            <div className="row align-items-center mb-4 flex-md-row-reverse">
                <div className="col-md-6 col-12">
                    <div className="img-holder">
                        <img src="/images/white-label.png" alt="white-label" className="img-fluid w-100" />
                    </div>
                </div>
                <div className="col-md-6 col-12">
                    <div className="text-holder">
                        <span className="tag">{t("our_solutions.tag_retail")}</span>
                        <h3 className="third-heading">{t("our_solutions.cobrand_subheading")}</h3>
                        <p>{t("our_solutions.cobrand_text")}</p>
                        <Link to="/contact" className="link">{t("our_solutions.learn_more")}</Link>
                    </div>
                </div>
            </div>
            <div className="row align-items-center mb-4 reverse">
                <div className="col-md-6 col-12">
                    <div className="img-holder">
                        <img src="/images/embedded-app.png" alt="white-label" className="img-fluid w-100" />
                    </div>
                </div>
                <div className="col-md-6 col-12">
                    <div className="text-holder">
                        <span className="tag">{t("our_solutions.tag_custom")}</span>
                        <h3 className="third-heading">{t("our_solutions.embedded_subheading")}</h3>
                        <p>{t("our_solutions.embedded_text")}</p>
                        <Link to="/contact" className="link">{t("our_solutions.learn_more")}</Link>
                    </div>
                </div>
            </div>
            <div className="row align-items-center mb-4 flex-md-row-reverse">
                <div className="col-md-6 col-12">
                    <div className="img-holder">
                        <img src="/images/marketplace-img.png" alt="white-label" className="img-fluid w-100" />
                    </div>
                </div>
                <div className="col-md-6 col-12">
                    <div className="text-holder">
                        <span className="tag">{t("our_solutions.tag_retail")}</span>
                        <h3 className="third-heading">{t("our_solutions.marketplace_subheading")}</h3>
                        <p>{t("our_solutions.marketplace_text")}</p>
                        <Link to="/contact" className="link">{t("our_solutions.learn_more")}</Link>
                    </div>
                </div>
            </div>
        </section>
    );
}
