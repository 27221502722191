import React from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const Footer = () => {
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };
    const {t} = useTranslation();
    return (
        <footer id="footer">
            <div className="container">
                <div className="row">
                    {/* <div className="col-md-8">
                        <div className="accordion accordion-flush d-md-flex flex-wrap" id="footerAccordion">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="footerHeadingOne">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#footerCollapseOne" aria-expanded="true"
                                            aria-controls="footerCollapseOne">
                                        {t("footer.rewards_and_loyalty")}
                                    </button>
                                </h2>
                                <div id="footerCollapseOne" className="accordion-collapse collapse show"
                                     aria-labelledby="footerHeadingOne" data-bs-parent="#footerAccordion">
                                    <div className="accordion-body">
                                        <ul className="list-unstyled footer_links">
                                            <li><Link to="https://business.theentertainerme.com/program-platforms" target="_blank">{t("footer.rewards_and_loyalty_links.platforms")}</Link></li>
                                            <li><Link to="https://business.theentertainerme.com/customer-loyalty-programs" target="_blank">{t("footer.rewards_and_loyalty_links.customer_loyalty")}</Link></li>
                                            <li><Link to="https://business.theentertainerme.com/integrations" target="_blank">{t("footer.rewards_and_loyalty_links.solutions_integration")}</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="footerHeadingTwo">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#footerCollapseTwo"
                                            aria-expanded="false" aria-controls="footerCollapseTwo">
                                        {t("footer.company")}
                                    </button>
                                </h2>
                                <div id="footerCollapseTwo" className="accordion-collapse collapse"
                                     aria-labelledby="footerHeadingTwo" data-bs-parent="#footerAccordion">
                                    <div className="accordion-body">
                                        <ul className="list-unstyled footer_links">
                                            <li><Link to="/about">{t("footer.company_links.about_us")}</Link></li>
                                            <li><Link to="https://business.theentertainerme.com/industry" target="_blank">{t("footer.company_links.industries")}</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="footerHeadingThree">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#footerCollapseThree"
                                            aria-expanded="false" aria-controls="footerCollapseThree">
                                        {t("footer.help_and_support")}
                                    </button>
                                </h2>
                                <div id="footerCollapseThree" className="accordion-collapse collapse"
                                     aria-labelledby="footerHeadingThree" data-bs-parent="#footerAccordion">
                                    <div className="accordion-body">
                                        <ul className="list-unstyled footer_links">
                                            <li><Link to="https://business.theentertainerme.com/resources" target="_blank">{t("footer.help_and_support_links.resource_center")}</Link></li>
                                            <li><Link to="https://business.theentertainerme.com/press-room" target="_blank">{t("footer.help_and_support_links.press_room")}</Link></li>
                                            <li><Link to="https://business.theentertainerme.com/blog-v2" target="_blank">{t("footer.help_and_support_links.blog")}</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="col-12 text-center">
                        <Link className="ft-logo d-inline-flex mb-2" to="/" onClick={scrollToTop}>
                            <img src="/images/Logo.webp" alt="the ENTERTAINER" className="img-fluid" />
                        </Link>
                        <ul className="list-unstyled social-list">
                            <li>
                                <Link to="https://www.instagram.com/weareentertainer/" className="social-icon" target="_blank">
                                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0ZM18 34.3636C8.96259 34.3636 1.63638 27.0374 1.63638 18C1.63638 8.96259 8.96259 1.63638 18 1.63638C27.0374 1.63638 34.3636 8.96259 34.3636 18C34.3636 27.0374 27.0374 34.3636 18 34.3636Z"
                                            fill="#484848"/>
                                        <path
                                            d="M22.9092 9.81812H13.0911C11.2836 9.81812 9.81836 11.2834 9.81836 13.0908V22.909C9.81836 24.7164 11.2836 26.1817 13.0911 26.1817H22.9092C24.7167 26.1817 26.1819 24.7164 26.1819 22.909V13.0909C26.182 11.2834 24.7167 9.81812 22.9092 9.81812ZM21.2728 12.2727H23.7274V14.7272H21.2728V12.2727ZM18.0001 14.7273C19.8076 14.7273 21.2728 16.1925 21.2728 18C21.2728 19.8074 19.8076 21.2727 18.0001 21.2727C16.1927 21.2727 14.7274 19.8074 14.7274 18C14.7274 16.1925 16.1927 14.7273 18.0001 14.7273ZM24.5456 22.909C24.5456 23.8128 23.8129 24.5454 22.9092 24.5454H13.0911C12.1873 24.5454 11.4547 23.8128 11.4547 22.909V17.1817H13.1728C12.6815 19.8481 14.4446 22.4079 17.1109 22.8993C19.7772 23.3907 22.337 21.6275 22.8284 18.9612C22.8869 18.6442 22.9139 18.3223 22.9092 18C22.9051 17.7254 22.8778 17.4517 22.8274 17.1818H24.5455V22.909H24.5456Z"
                                            fill="#484848"/>
                                    </svg>
                                </Link>
                            </li>
                            <li>
                                <Link to="https://bit.ly/44v0T87" className="social-icon" target="_blank">
                                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_1555_274)">
                                            <path d="M19.4893 16.7749L25.3176 10H23.9365L18.8758 15.8826L14.8338 10H10.1719L16.2841 18.8955L10.1719 26H11.5531L16.8973 19.7878L21.1659 26H25.8278L19.489 16.7749H19.4893ZM17.5976 18.9738L16.9783 18.0881L12.0507 11.0397H14.1722L18.1487 16.728L18.768 17.6137L23.9371 25.0075H21.8157L17.5976 18.9742V18.9738Z" fill="#484848"/>
                                        </g>
                                        <path d="M18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0ZM18 34.3636C8.96259 34.3636 1.63638 27.0374 1.63638 18C1.63638 8.96259 8.96259 1.63638 18 1.63638C27.0374 1.63638 34.3636 8.96259 34.3636 18C34.3636 27.0374 27.0374 34.3636 18 34.3636Z" fill="#484848"/>
                                        <defs>
                                            <clipPath id="clip0_1555_274">
                                                <rect width="16" height="16" fill="white" transform="translate(10 10)"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </Link>
                            </li>
                            <li>
                                <Link to="https://www.linkedin.com/company/the-entertainer-/?viewAsMember=true" className="social-icon" target="_blank">
                                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0ZM18 34.3636C8.96259 34.3636 1.63638 27.0374 1.63638 18C1.63638 8.96259 8.96259 1.63638 18 1.63638C27.0374 1.63638 34.3636 8.96259 34.3636 18C34.3636 27.0374 27.0374 34.3636 18 34.3636Z" fill="#484848"/>
                                        <path d="M26.9962 24.9986L27.0005 24.9979V18.7638C27.0005 15.714 26.3439 13.3647 22.7785 13.3647C21.0645 13.3647 19.9143 14.3053 19.4448 15.197H19.3952V13.6495H16.0146V24.9979H19.5347V19.3786C19.5347 17.899 19.8152 16.4683 21.6474 16.4683C23.4528 16.4683 23.4797 18.1568 23.4797 19.4735V24.9986H26.9962Z" fill="#484848"/>
                                        <path d="M10.2793 13.6489H13.8036V24.9974H10.2793V13.6489Z" fill="#484848"/>
                                        <path d="M12.0412 8C10.9144 8 10 8.91436 10 10.0412C10 11.168 10.9144 12.1015 12.0412 12.1015C13.168 12.1015 14.0824 11.168 14.0824 10.0412C14.0817 8.91436 13.1673 8 12.0412 8Z" fill="#484848"/>
                                    </svg>
                                </Link>
                            </li>
                        </ul>
                        <p>© {t("footer.entertainer_copy_write")}</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}
