import React from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const Hero = () => {
    const {t} = useTranslation();
    return (
        <div className="heroSection main">
            <div className="container">
                <div className="row">
                    <div className="col-md-7 col-12">
                        <div className="text-holder">
                            <h1>{t("hero_section.heading")}</h1>
                            <Link to="/contact" className="btn btn-primary text-uppercase">{t("hero_section.request_demo")}</Link>
                        </div>
                    </div>
                    <div className="col-md-5 col-12">
                        <div className="img-holder">
                            <img src="/images/iphone-group.png" alt="iphone" className="img-fluid img-en" />
                            <img src="/images/iphone-group-ar.png" alt="iphone" className="img-fluid img-ar" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
