import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useTabContext} from "../../../context/TabContext";
import {ApiWrapper} from "../../../api/ApiWrapper";

export const Business  = (
    {
        onBusinessFinish,
        onBusinessSelection,
        setSetupFee
    }) => {
    const tab = useTabContext();

    const {t} = useTranslation();
    const businessOption = [
        {
            id: 1,
            label: t("calculator.select"),
            value: "select",
        },
        {
            id: 2,
            label: t("calculator.business_background_dropdown.telecommunications"),
            value: "telecommunications"
        },
        {
            id: 3,
            label: t("calculator.business_background_dropdown.insurance"),
            value: "insurance"
        },
        {
            id: 4,
            label: t("calculator.business_background_dropdown.transport"),
            value: "transport"
        },
        {
            id: 5,
            label: t("calculator.business_background_dropdown.construction"),
            value: "construction"
        },
        {
            id: 5,
            label: t("calculator.business_background_dropdown.consultations_firm"),
            value: "consultations_firm"
        },
        {
            id: 6,
            label: t("calculator.business_background_dropdown.others"),
            value: "others"
        },
    ];
    const businessPlatform = [
        {
            id: 1,
            label: t("calculator.select"),
            value: "select",
        },
        {
            id: 2,
            label: t("calculator.like_app_dropdown.employees"),
            value: "employees",
        },
        {
            id: 3,
            label: t("calculator.like_app_dropdown.customers"),
            value: "customers",
        },
        {
            id: 4,
            label: t("calculator.like_app_dropdown.other"),
            value: "other",
        },
    ];
    const labelValue = localStorage.getItem("selectedLanguage") === 'ar' ? "+5000" : "5000+"
    const companySizeList = [
        {
            id: 1,
            label: t("calculator.select"),
            value: "select",
        },
        {
            id: 2,
            label: "0-50",
            value: "0-50",
        },
        {
            id: 3,
            label: "50-100",
            value: "50-100",
        },
        {
            id: 4,
            label: "100-150",
            value: "100-150",
        },
        {
            id: 5,
            label: "150-200",
            value: "150-200",
        },
        {
            id: 6,
            label: "200-250",
            value: "200-250",
        },
        {
            id: 7,
            label: "250-300",
            value: "250-300",
        },
        {
            id: 8,
            label: "300-350",
            value: "300-350",
        },
        {
            id: 9,
            label: "350-400",
            value: "350-400",
        },
        {
            id: 10,
            label: "400-450",
            value: "400-450",
        },
        {
            id: 11,
            label: "450-500",
            value: "450-500",
        },
        {
            id: 12,
            label: "500-1000",
            value: "500-1000",
        },
        {
            id: 13,
            label: "1000-5000",
            value: "1000-5000",
        },
        {
            id: 14,
            label: "5000+",
            value: "5000+",
        }
    ];

    const [business, setBusiness] = useState('');
    const [businessOthers, setBusinessOthers] = useState('');
    const [platform, setPlatform] = useState('');
    const [platformOther, setPlatformOther] = useState('');
    const [companySize, setCompanySize] = useState('');
    const [email, setEmail] = useState('');
    const [contact, setContact] = useState('');
    const [formErrors, setFormErrors] = useState({});
    useEffect(()=> {
        (async () => {
            const data = await ApiWrapper({
                path:"/packages",
                method:"GET",
                data:""
            });
            console.log(data,"data");
            localStorage.setItem("packagesData", JSON.stringify(data.data));
        })();
        (async () => {
            const data = await ApiWrapper({
                path:"/startupfee",
                method:"GET",
                data:""
            });
            if(data.status_code === 200) {
                console.log(data.data[0],"fee");
                setSetupFee(data.data[0]);
            }
        })();
    },[]);

    const BusinessOptionHandler = async (event) => {
        setBusiness(event.target.value);
        // setIsLoadingSetupFee(true);
        // const data = await ApiWrapper({
        //     path:"/startupfee",
        //     method:"GET",
        //     data:""
        // });
        // if(data.status_code === 200) {
        //     if(event.target.value === 'new') {
        //         setInitialSetupFee(parseInt(data.data[0].new_business_fee));
        //     }
        //     else if(event.target.value === 'renew') {
        //         setInitialSetupFee(parseInt(data.data[0].renewal_business_fee));
        //     } else {
        //         setInitialSetupFee(0);
        //     }
        //     setSetupFee(data.data[0]);
        //     setIsLoadingSetupFee(false);
        // }
        onBusinessSelection(event.target.value);
    }

    const BusinessOptionOthersHandler = (event) => {
        setBusinessOthers(event.target.value);
    }

    const BusinessPlatformHandler = (event) => {
        setPlatform(event.target.value);
    }

    const BusinessPlatformOtherHandler = (event) => {
        setPlatformOther(event.target.value);
    }

    const CompanySizeHandler = (event) => {
        setCompanySize(event.target.value);
    }

    const EmailHandler  = (event) => {
        setEmail(event.target.value)
    }

    const ContactNumberHandler = (event) => {
        const value = event.target.value;
        if (/^\+?\d*$/.test(value)) {
            setContact(value);
        }
    }

    const BusinessSubmitHandler = (event) => {
        event.preventDefault();
        if(validateForm()) {
            const businessData = {
                businessEmail: email,
                businessContact: contact,
                businessBackground: business,
                businessPlatform: platform,
                businessCompanySize: companySize
            }
            onBusinessFinish(businessData);
            tab.setActiveTab(t("calculator.tabs.market"));
        }
    }

    const validateForm = () => {
        let errors = {};
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!email) {
            errors.email = t("calculator.business_fields.validation_messages.email");
        }
        if(email && !emailRegex.test(email)) {
            errors.email = t("calculator.business_fields.validation_messages.email_invalid");
        }

        if (!contact) {
            errors.contact = t("calculator.business_fields.validation_messages.phone");
        }
        if(contact && (contact.length < 10 || contact.length > 15)) {
            errors.contact = t("calculator.business_fields.validation_messages.phone");
        }

        if (!business || business === 'select') {
            errors.business = t("calculator.business_fields.validation_messages.business_background");
        }

        if (!companySize || companySize === 'select') {
            errors.companySize = t("calculator.business_fields.validation_messages.company_size");
        }

        if (!platform || platform === 'select') {
            errors.platform = t("calculator.business_fields.validation_messages.user_audience");
        }

        // Set formErrors state
        setFormErrors(errors);

        // Return true if there are no errors
        return Object.keys(errors).length === 0;
    };

    return (
        <div>
            <form onSubmit={BusinessSubmitHandler}>
                <div className="tab-pane form-wrap">
                    <div className="row">
                        <div className="col-lg-6 col-12">
                            <div className="field-wrap">
                                <label className="form-label" htmlFor="email_address">{t("calculator.business_fields.email_address")}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="email_address"
                                    placeholder={t("calculator.business_fields.email_placeholder")}
                                    value={email}
                                    onChange={EmailHandler}
                                />
                                {formErrors.email && <span className="error-color">{formErrors.email}</span>}
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="field-wrap">
                                <label className="form-label" htmlFor="contact_number">{t("calculator.business_fields.contact_number")}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="contact_number"
                                    placeholder={t("calculator.business_fields.placeholder.phone")}
                                    value={contact}
                                    onChange={ContactNumberHandler}
                                />
                                {formErrors.contact && <span className="error-color">{formErrors.contact}</span>}
                            </div>
                        </div>
                    </div>
                    <div className="field-wrap">
                        <label className="form-label" htmlFor="business_background">{t("calculator.business_fields.business_background")}</label>
                        <div className="select-wrap">
                            <select
                                className="form-select"
                                id="business_background"
                                aria-label="Business Background"
                                value={business}
                                onChange={BusinessOptionHandler}
                            >
                                {businessOption.map((option) => (
                                    <option key={option.id} value={option.value} defaultValue={option[0]}>{option.label}</option>
                                ))}
                            </select>
                        </div>
                        {formErrors.business && <span className="error-color">{formErrors.business}</span>}
                    </div>
                    <div className="field-wrap">
                        <textarea
                            className={`form-control ${business === 'others' ? '' : 'd-none'}`}
                            placeholder={t("calculator.business_fields.placeholder.others")}
                            value={businessOthers}
                            onChange={BusinessOptionOthersHandler}
                        >
                        </textarea>
                    </div>
                    <div className="field-wrap">
                        <label className="form-label" htmlFor="company_size">{t("calculator.business_fields.company_size")}</label>
                        <div className="select-wrap">
                            <select
                                className="form-select"
                                id="company_size"
                                value={companySize}
                                onChange={CompanySizeHandler}
                            >
                                {companySizeList.map((companySize) => (
                                    <option key={companySize.id} value={companySize.value} defaultValue={companySize[0]}>{companySize.label}</option>
                                ))}
                            </select>
                        </div>
                        {formErrors.companySize && <span className="error-color">{formErrors.companySize}</span>}
                    </div>
                    <div className="field-wrap">
                        <label className="form-label" htmlFor="app_use">{t("calculator.business_fields.like_app")}</label>
                        <div className="select-wrap">
                            <select
                                className="form-select"
                                id="app_use"
                                aria-label="Business Type"
                                value={platform}
                                onChange={BusinessPlatformHandler}
                            >
                                {businessPlatform.map((platform) => (
                                    <option key={platform.id} value={platform.value} defaultValue={'employees'}>{platform.label}</option>
                                ))}
                            </select>
                        </div>
                        {formErrors.platform && <span className="error-color">{formErrors.platform}</span>}
                    </div>
                    <div className="field-wrap">
                        <textarea
                            className={`form-control ${platform === 'other' ? '' : 'd-none'}`}
                            placeholder={t("calculator.business_fields.placeholder.others")}
                            value={platformOther}
                            onChange={BusinessPlatformOtherHandler}
                        >
                        </textarea>
                    </div>
                </div>
                <button className="btn btn-primary d-block w-100 ">{t("calculator.next")}</button>
            </form>
        </div>
    )
}