import {createContext, useContext, useState} from "react";
import {useTranslation} from "react-i18next";

const TabContext = createContext(null);

export const TabProvider = ({children}) => {
    const {t} = useTranslation();
    const [activeTab, setActiveTab] = useState(t("calculator.tabs.business"))

    return (
        <TabContext.Provider value={{activeTab, setActiveTab}}>
            {children}
        </TabContext.Provider>
    )
}

export const useTabContext = () => useContext(TabContext);