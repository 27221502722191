import React from "react";
import {useTranslation} from "react-i18next";

export const BrandsSection = () => {
    const {t} = useTranslation();
    return (
        <section className="brands-section">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12">
                        <h2 className="second-heading text-center">{t("brands_section.heading")}</h2>
                    </div>
                </div>
                <div className="row row-cols-2 row-cols-md-5">
                    <div className="col d-flex align-items-center column">
                        <div className="img-holder">
                            <img src="/images/brands/abdullah-al-othaim@2x.png" alt="ABDULLAH AL OTHAIM" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col d-flex align-items-center column">
                        <div className="img-holder">
                            <img src="/images/brands/doubleTree@2x.png" alt="DOUBLE TREE" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col d-flex align-items-center column">
                        <div className="img-holder">
                            <img src="/images/brands/mobily@2x.png" alt="MOBILY" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col d-flex align-items-center column">
                        <div className="img-holder">
                            <img src="/images/brands/jasara@2x.png" alt="JASARA" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col d-flex align-items-center column">
                        <div className="img-holder">
                            <img src="/images/brands/nahdi@2x.png" alt="NAHDI" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col d-flex align-items-center column">
                        <div className="img-holder">
                            <img src="/images/brands/marriott@2x.png" alt="NAHDI" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col d-flex align-items-center column">
                        <div className="img-holder">
                            <img src="/images/brands/rayhaan@2x.png" alt="NAHDI" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col d-flex align-items-center column">
                        <div className="img-holder">
                            <img src="/images/brands/nissan@2x.png" alt="NAHDI" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col d-flex align-items-center column">
                        <div className="img-holder">
                            <img src="/images/brands/nandos@2x.png" alt="NAHDI" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col d-flex align-items-center column">
                        <div className="img-holder">
                            <img src="/images/brands/ey@2x.png" alt="NAHDI" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
