import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

export const SummarySection = (props) => {

    const {t} = useTranslation()

    let setupFee = props.setupFee;
    let marketFee = props.marketFee;
    let advanceReportingFee = props.advanceReportingFee;
    let totalAnnualFee = marketFee + advanceReportingFee;


    return (
        <div className="col-xl-5 col-12">
            <div className="calc-block">
                <div className="head d-flex justify-content-between align-items-center">
                    <h2 className="second-heading">{t("calculator.summary_fields.heading")}</h2>
                </div>
                <ul className="sum-list">
                    <li>
                        <ul className="sub-list">
                            <li className="d-flex justify-content-between align-items-center">
                                <span className="title">{t("calculator.summary_fields.marketing_fee")}</span>
                                <span className="sum">{t("calculator.currency")}{marketFee.toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2,})}</span>
                            </li>
                            <li className="d-flex justify-content-between align-items-center">
                                <span className="title">{t("calculator.summary_fields.advanced_reporting_label")}</span>
                                <span className="sum">{t("calculator.currency")}{advanceReportingFee.toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2,})}</span>
                            </li>
                        </ul>
                    </li>
                    <li className="d-flex justify-content-between total">
                        <p>
                            <span className="title">{props.isMonthly ? t("calculator.summary_fields.monthly_fee") : t("calculator.summary_fields.total_fee")}</span>
                            <span className="note">{t("calculator.summary_fields.tax")}</span>
                            <span className="note">{t("calculator.summary_fields.estimated")}</span>
                        </p>
                        <span className="sum">
                            {t("calculator.currency")}
                            {localStorage.getItem("selectedLanguage") === 'ar' ? (setupFee > 0 ? "*" : "") : ""}
                            {totalAnnualFee.toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2,})}
                            {localStorage.getItem("selectedLanguage") === 'en' ? (setupFee > 0 ? "*" : "") : ""}
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    )
}