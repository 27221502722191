import React from "react";
import {useTabContext} from "../../context/TabContext";
import {useTranslation} from "react-i18next";

export const PricingTabs = (
    {
        // onChangeTab,
        pricingTabList
        // activeTab}
    }) => {
    const tabContext = useTabContext();
    const {t} = useTranslation();
    return (
        <ul className="nav nav-pills mb-3">
            {pricingTabList.map((tab) => (
                <li className="nav-item" key={tab.id}>
                    <button onClick={ () => { tabContext.setActiveTab(t(tab.value)) } }
                        className={`${tabContext.activeTab === tab.label ? tab.class + ' active' : tab.class}`}
                    >{tab.label}
                    </button>
                </li>
            ))}
        </ul>
    )
}