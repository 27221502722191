import {BrowserRouter as Router, Routes, Route, useLocation} from "react-router-dom";
import {Header} from "./components/Header";
import {Home} from "./Pages/Home";
import {Footer} from "./components/Footer";
import {About} from "./Pages/About";
import {Contact} from "./Pages/Contact";
import {useEffect} from "react";
import {TabProvider} from "./context/TabContext";
import {ApiWrapper, getToken} from "./api/ApiWrapper";

const path = process.env.REACT_APP_GET_AUTH_TOEKN_ENDPOINT;

const Pages = () => {

    const { pathname } = useLocation();

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, [pathname]);
    useEffect(() => {
        const handlePopstate = () => {
            if(window.location.pathname === "/") {
                window.location.href=''
            }
        };

        // Add the event listener when the component mounts
        window.addEventListener('popstate', handlePopstate);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('popstate', handlePopstate);
        };
    }, []);

    useEffect(() => {
        getToken();
    },[]);

    return (
        <TabProvider>
            <Header pathname={pathname} />
            <Routes>
                <Route path="/" exact element={<Home />}></Route>
                <Route path="/about" element={<About />}></Route>
                <Route path="/contact" element={<Contact />}></Route>
            </Routes>
            <Footer />
        </TabProvider>
    );
}
function App() {

  return (
    <div className="App">
        <Router>
            <Pages/>
        </Router>
    </div>
  );
}

export default App;
