import React, {useEffect, useRef, useState, useCallback } from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {CountryModal} from "./CountryModal";
import {useTranslation} from "react-i18next";
import {useTabContext} from "../../../context/TabContext";
import { ApiWrapper } from "../../../api/ApiWrapper";
import { MultiSelect } from "react-multi-select-component";


const monthWithPercentage = [
    {
        month: 12,
        percentage: "100%",
        value: 1
    },
    {
        month: 11,
        percentage: "94%",
        value: 0.94
    },
    {
        month: 10,
        percentage: "88%",
        value: 0.88
    },
    {
        month: 9,
        percentage: "82%",
        value: 0.82
    },
    {
        month: 8,
        percentage: "75%",
        value: 0.75
    },
    {
        month: 7,
        percentage: "67%",
        value: 0.67
    },
    {
        month: 6,
        percentage: "59%",
        value: 0.59
    },
    {
        month: 5,
        percentage: "50%",
        value: 0.50
    },
    {
        month: 4,
        percentage: "41%",
        value: 0.41
    },
    {
        month: 3,
        percentage: "32%",
        value: 0.32
    },
    {
        month: 2,
        percentage: "22%",
        value: 0.22
    },
    {
        month: 1,
        percentage: "11%",
        value: 0.11
    },


];
// Function to round the number based on the specified rules
//If the user inputs a number between 200 and 1000, round to the nearest multiple of 50.
//If the user inputs a number between 1000 and 500,000, round to the nearest multiple of 1000.
const roundNumber = (number) => {
    if (number >= 200 && number <= 1000) {
        return Math.round(number / 50) * 50;
    } else if (number > 1000 && number <= 500000) {
        return Math.round(number / 1000) * 1000;
    }
    return number;
};

// Hook to debounce any fast-changing value
const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
};
export const Market = (

    {
        onMarketFinish,
        onRewardsUnitSelection,
        setVoucher,
        voucherList,
        vouchers,
        merchants,
        setMerchants,
        merchantNumberList,
        selectedCountries,
        onCountrySelection,
        setActiveTab,
        country,
        contentLicenseFee,
        setContentLicenseFee,
        selectedContentLicenseFee,
        setSelectedContentLicenseFee,
        setIsLoadingContentLicenseFee,
        contractPeriod,
        setContractPeriod,
        calculateMarketFee,
        selectedLocations,
        setSelectedLocations
    }
    ) => {

    useEffect(()=> {
        (async () => {
            const data = await ApiWrapper({
                path:"/locationpercentage",
                method:"GET",
                data:""
            });
            localStorage.setItem("locationPercentage", JSON.stringify(data.data));
        })();
    },[]);
        
    const { t } = useTranslation();

    const rewardsUnitList = [
        {
            id: 1,
            label: t("calculator.select"),
            value: "select",
        },
        {
            id: 2,
            label: "0-200",
            value: "0-200",
        },
        {
            id: 3,
            label: "200-250",
            value: "200-250",
        },
        {
            id: 4,
            label: "250-300",
            value: "250-300",
        },
        {
            id: 5,
            label: "300-350",
            value: "300-350",
        },
        {
            id: 6,
            label: "350-400",
            value: "350-400",
        },
        {
            id: 7,
            label: "400-450",
            value: "400-450",
        },
        {
            id: 8,
            label: "450-500",
            value: "450-500",
        },
        {
            id: 9,
            label: "500-1000",
            value: "500-1000",
        },
        {
            id: 10,
            label: "1000-5000",
            value: "1000-5000",
        },
        {
            id: 11,
            label: "5000+",
            value: "5000+",
        }
    ];

    const regionList = [
        {
            id: 1,
            label: t("calculator.market_fields.region_list.ksa"),
            value: "KSA",
        },
        {
            id: 2,
            label: t("calculator.market_fields.region_list.qatar"),
            value: "Qatar",
        },
        {
            id: 3,
            label: t("calculator.market_fields.region_list.bahrain"),
            value: "Bahrain",
        },
        {
            id: 4,
            label: t("calculator.market_fields.region_list.kuwait"),
            value: "Kuwait",
        },
        {
            id: 5,
            label: t("calculator.market_fields.region_list.oman"),
            value: "Oman",
        },
        {
            id: 6,
            label: t("calculator.market_fields.region_list.uae"),
            value: "UAE",
        }
    ];

    const contractPeriodList = [
        {
            id: 1,
            label: 1,
            value: 1,
        },
        {
            id: 2,
            label: 2,
            value: 2,
        },
        {
            id: 3,
            label: 3,
            value: 3,
        },
        {
            id: 4,
            label: 4,
            value: 4,
        },
        {
            id: 5,
            label: 5,
            value: 5,
        },
        {
            id: 6,
            label: 6,
            value: 6,
        },
        {
            id: 7,
            label: 7,
            value: 7,
        },
        {
            id: 8,
            label: 8,
            value: 8,
        },
        {
            id: 9,
            label: 9,
            value: 9,
        },
        {
            id: 10,
            label: 10,
            value: 10,
        },
        {
            id: 11,
            label: 11,
            value: 11,
        },
        {
            id: 12,
            label: 12,
            value: 12,
        }
    ];

    const [formErrors, setFormErrors] = useState({});
    const [rewardsUnit, setRewardsUnit] = useState("");
    const [countryDropdown, setCountryDropdown] = useState(false);
    const getPackagesData = localStorage.getItem("packagesData");
    const [monthPercentageValue, setMonthPercentageValue] = useState("");
    const [rewardsUnitSelected, setRewardsUnitSelected] = useState(false);
    const tab = useTabContext();

    let totalMonths = contractPeriod;

    
    const VoucherPerMerchantHandler = (event) => {
        setVoucher(event.target.value);

        const element = JSON.parse(getPackagesData).filter((item) =>
            item.no_of_keys === rewardsUnit
            && item.merchant_location === country
            && item.no_of_merchants == merchants
        );
        if(element.length > 0){
            const rewardsUnitAmount = parseFloat(element[0].price.replace(/,/g, ''));
        }
        if(monthWithPercentage.length > 0){
            const monthPercentage = monthWithPercentage.filter((item) =>
                item.month === contractPeriod
            );
            if (totalMonths > 0 && monthPercentage.length > 0) {
                setMonthPercentageValue(monthPercentage[0].value);
            }
        }
    
    }

    const MerchantNumberHandler = (event) => {
        setMerchants(event.target.value);
        calculateMarketFee();
    }

    const validateForm = () => {
        let errors = {};
        if (!rewardsUnit) {
            const isValidNumber = /^\d+$/.test(rewardsUnit);
            const numericValue = parseInt(rewardsUnit, 10);

            if (rewardsUnit === '' || (isValidNumber && numericValue < 200 && numericValue > 500000)) {
                errors.rewardsUnit = t("calculator.market_fields.validation_messages.rewards_unit");
            }
        }

        // Set formErrors state
        setFormErrors(errors);

        // Return true if there are no errors
        return Object.keys(errors).length === 0;
    };

    const MarketSubmitHandler = (event) => {
        event.preventDefault();
        if(validateForm()) {
            const marketData = {
                rewardsUnit: rewardsUnit,
                defaultCountry: country,
                noOfMerchants: merchants,
                noOfCountries: selectedLocations.length,
                offersPerMerchant: vouchers,
                contractPeriod: contractPeriod
            }
            onMarketFinish(marketData);
            tab.setActiveTab(t("calculator.tabs.marketing"));
        }
    }

    const CountryModalHandler = () => {
        setCountryDropdown(true);
    }

    const locationHandler = (selected) => {
        const permanentOption = {
            id: 1,
            label: t("calculator.market_fields.region_list.ksa"),
            value: "KSA",
        };
        // Ensure the permanent option is always included
        if (!selected.some(option => option.value === permanentOption.value)) {
            selected.push(permanentOption);
        }
        setSelectedLocations(selected);
    };

    const contractPeriodHandler = (event) => {
        setContractPeriod(event.target.value);
    };

    // Debounce the rewardsUnit state to avoid frequent updates
    const debouncedRewardsUnit = useDebounce(rewardsUnit, 500);
    // Use useCallback to avoid recreating this function on each render
    const RewardsUnitHandler = useCallback(
        async (event) => {
            let errors = {};
            let calculatedRoundedValue = 0;
            const inputValue = event.target.value;
            const isValidNumber = /^\d+$/.test(inputValue);
            const numericValue = parseInt(inputValue, 10);

            if ((isValidNumber && numericValue >= 200 && numericValue <= 500000)) {
                // Set formErrors state
                setFormErrors(errors);
                setRewardsUnit(inputValue);
                calculatedRoundedValue = roundNumber(numericValue);
                // Assuming getPackagesData is a state or prop that holds your data
                if (getPackagesData !== 'undefined') {
                    onRewardsUnitSelection(event.target.value);
                    calculateMarketFee();
                } else {
                    // Alert or handle no record found case
                    console.log('No PackagesData found');
                }
            } else {
                errors.rewardsUnit = t("calculator.market_fields.validation_messages.rewards_unit");
                // Set formErrors state
                setFormErrors(errors);
            }
        },
        [country, merchants, onRewardsUnitSelection]
    );
    useEffect(() => {
        console.log(debouncedRewardsUnit, "debouncedRewardsUnit");
        if (debouncedRewardsUnit) {
            // Trigger the handler when debounced value changes
            RewardsUnitHandler({ target: { value: debouncedRewardsUnit } });
        }
    }, [debouncedRewardsUnit, RewardsUnitHandler]);

    

    const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Toggle the dropdown
  const toggleDropdown = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  // Prevent closing the dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // Prevent toggling when clicked outside
        event.stopPropagation();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);


    return (
        <div>
            <form onSubmit={MarketSubmitHandler}>
                <div className="tab-pane form-wrap">

                    <div className="row align-items-center field-wrap">
                        <div className="col-md-8 col-12">
                            <label className="form-label mb-md-0" htmlFor="home_country">{t("calculator.market_fields.country")}<span className="text-danger">*</span></label>
                        </div>
                        <div className="col-md-4 col-12 ps-md-0">
                            <div className="select-wrap">
                                <MultiSelect
                                    options={regionList}
                                    value={selectedLocations}
                                    onChange={locationHandler}
                                    labelledBy="Select Location"
                                    className="custom-multi-select"
                                    // isOpen={true} 
                                />
                            </div>

                        </div>
                    </div>

                    <div className="row align-items-center field-wrap">
                        <div className="col-md-8 col-12">
                            <label className="form-label mb-md-0" htmlFor="merchant_nums">{t("calculator.market_fields.merchant_number")}<span className="text-danger">*</span></label>
                        </div>

                        <div className="col-md-4 col-12 ps-md-0">
                            <div className="select-wrap">
                                <select
                                    className="form-select"
                                    id="app_units"
                                    aria-label="Business Type"
                                    value={merchants}
                                    onChange={MerchantNumberHandler}
                                >
                                    {merchantNumberList.map((merchantNumber) => (
                                        <option key={merchantNumber.id} value={merchantNumber.value}>{merchantNumber.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center field-wrap">
                        <div className="col-md-8 col-12">
                            <label className="form-label mb-md-0" htmlFor="merchant_offers">{t("calculator.market_fields.merchant_offer")}<span className="text-danger">*</span></label>
                        </div>
                        <div className="col-md-4 col-12 ps-md-0">
                            <div className="select-wrap">
                                <select
                                    className="form-select"
                                    id="merchant_offers"
                                    aria-label="Business Type"
                                    value={vouchers}
                                    onChange={VoucherPerMerchantHandler}
                                >
                                    {voucherList.map((voucher) => (
                                        <option key={voucher.id} value={voucher.value}>{voucher.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center field-wrap">
                        <div className="col-md-8 col-12">
                            <label className="form-label mb-md-0" htmlFor="app_units">{t("calculator.market_fields.app_users")}<span className="text-danger">*</span></label>
                        </div>
                        <div className="col-md-4 col-12 ps-md-0">
                            <div className="">
                                <input
                                    id="app_units"
                                    type="text"
                                    className="form-control"
                                    value={rewardsUnit}
                                    onChange={(e) => setRewardsUnit(e.target.value)}
                                    placeholder="Enter"
                                />
                            </div>
                            {formErrors.rewardsUnit && <span className="error-color">{formErrors.rewardsUnit}</span>}
                        </div>
                    </div>

                    <div className="row align-items-center field-wrap validity-row">
                        <div className="col-md-8 col-12">
                            <label className="form-label mb-md-0" htmlFor="field-validity">{t("calculator.market_fields.contract_period")}<span className="text-danger">*</span></label>
                        </div>
                        <div className="col-md-4 col-12 ps-md-0">
                            <div className="select-wrap">
                                <select
                                    className="form-select"
                                    id="contract_period"
                                    aria-label="Contract Period"
                                    value={contractPeriod}
                                    onChange={contractPeriodHandler}
                                >
                                    {contractPeriodList.map((contractPeriod) => (
                                        <option key={contractPeriod.id} value={contractPeriod.value}>{contractPeriod.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="btn btn-primary d-block w-100">{t("calculator.next")}</button>
            </form>
        </div>
    )
}